<template>
    <div>
        <v-card-title>
            База клиентов
            <v-btn icon @click="$emit('forceUpdate')">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <v-btn large color="green" to="/clients/create">
                    Добавить клиента
                </v-btn>
            </div>
        </v-card-title>
        <v-data-table class="elevation-1"
                      :headers="headers"
                      :items="clients"
                      :options.sync="clientGridsOptions.options"
                      :server-items-length="totalElements"
                      show-select
                      single-select
                      v-model="selected"
                      item-key="id"
                      :loading="loading"
                      loading-text="Загрузка ..."
                      :footer-props="{'items-per-page-options': itemPerPage}"
                      :fixed-header="fixedHeader"
                      :height="tableHeight"
        >
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-if="!filter" class="row_sticky">
                    <td width="20" class="px-2"></td>
                    <td width="20" class="px-2"></td>
                    <td width="60">
                        <v-text-field v-model="clientGridsOptions.filter.id" type="text"></v-text-field>
                    </td>
                    <td width="80">
                        <v-select v-model="clientGridsOptions.filter.state" :items="states" clearable></v-select>
                    </td>
                    <td width="120">
                        <v-text-field v-model="clientGridsOptions.filter.cleanPhone" type="text"></v-text-field>
                    </td>
                    <td width="120">
                        <v-dialog ref="dialog"
                                  v-model="dialog.createdAt.modal"
                                  :return-value.sync="clientGridsOptions.filter.createdAtDates"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="clientGridsOptions.filter.createdAtDates"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        clearable
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.createdAt.model"
                                           type="date"
                                           scrollable
                                           range
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td width="120">
                        <v-dialog ref="dialog2"
                                  v-model="dialog.assignedAt.modal"
                                  :return-value.sync="clientGridsOptions.filter.assignedAtDate"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="clientGridsOptions.filter.assignedAtDate"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        clearable
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.assignedAt.model" type="date" scrollable
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.assignedAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog2.save(dialog.assignedAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('lawyer')" width="160">
                        <v-select v-model="clientGridsOptions.filter.lawyer" :items="lawyers" clearable
                                  item-value="id"
                                  item-text="fullName"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('userName')" width="160">
                        <v-text-field v-model="clientGridsOptions.filter.userName" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')" width="160">
                        <v-select v-model="clientGridsOptions.filter.subdivision" :items="subdivisions" clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                </tr>
                <tr v-for="(item, key) in items" :key="item.id" v-bind:class="{'grey darken-3': isHighlighted(item)}">
                    <td class="pr-0">
                        <v-checkbox v-if="$helper.permission.canDeleteClient(item, user)" v-model="selected" :value="item.id" hide-details class="ma-0"/>
                    </td>
                    <td class="px-2">
                        <v-dialog v-model="deleteDialog[item.id]" max-width="320"
                                  v-if="$helper.permission.canDeleteClient(item, user)">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td>
                        <a @click="editClient(item.id, items[key-1], items[key + 1])">{{ item.id }}</a>
                    </td>
                    <td>
                        <v-chip :color="item.state.chipColor" outlined dark>
                            {{item.state.text}}
                        </v-chip>
                    </td>
                    <td>
                        {{item.userPhone}}
                        <span v-if="item.isDouble === true && 1 === 0" style="padding-left: 10px">
                            <v-icon>mdi-repeat-once</v-icon>
                        </span>
                    </td>
                    <td>{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                    <td>{{ $moment(item.assignedAt).dateTimeFormat() }}</td>
                    <td v-if="isVisibleColumn('lawyer')">
                        <div v-if="item.lawyer">{{ item.lawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('userName')">
                        <div v-if="item.userName">{{ item.userName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')">
                        <div v-if="item.subdivision">{{ item.subdivision.name }}</div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>

        <v-bottom-sheet v-model="dialog.clientEdit.modal" fullscreen scrollable persistent>
            <v-card style="background: none">
                <v-card-text style="height: 100vh;">
                    <ClientUpdatePage v-if="editedClient" :id="editedClient" :inPopup="true" :nextClient="nextClient" :prevClient="prevClient" :key="editedClientKey" @changeEdited="changeEdited" @closeAndUpdate="closeEditAndFetch" @closeEdit="closeEdit"></ClientUpdatePage>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import ClientService from "../_services/ClientService";
    import {mapState} from 'vuex';
    import _ from "lodash";
    import UserService from "../_services/UserService";
    import SubdivisionService from "../_services/SubdivisionService";
    import ClientUpdatePage from "./ClientUpdatePage";

    export default {
        name: 'ClientGrid',
        components: {ClientUpdatePage},
        props: {
            filter: Object,
            title: String,
            exclude: Array
        },
        data: function() {
            return {
                clients: [],
                states: [],
                lawyers: [],
                subdivisions: [],
                selected: [],
                loading: true,
                totalElements: 0,
                dialog: {
                    createdAt: {
                        model: [],
                        modal: false
                    },
                    assignedAt: {
                        model: null,
                        modal: false
                    },
                    clientEdit: {
                        modal: false
                    }
                },
                deleteDialog: {},
                deleteBulkDialog: false,
                tableHeight:  !this.filter ? this.calcTableHeight() : null,
                fixedHeader: !this.filter,
                clientGridsOptions: !this.filter ? this.$store.state.clientGridsOptions :
                    {
                        options: {
                            sortBy: ['id'],
                            sortDesc: [true]
                        },
                        filter: this.filter
                    },
                editedClient: null,
                nextClient: null,
                prevClient: null,
                editedClientKey: 0
            }
        },
        watch: {
            clientGridsOptions: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            }
        },
        methods: {
            fetch() {
                if (this.filter) {
                    //this.fetchGrid();
                } else {
                    this.fetchStates();
                    this.fetchLawyers();
                    if (this.isVisibleColumn('subdivision')) {
                        this.fetchSubdivisions();
                    }
                }
            },
            fetchGrid() {
                this.loading = true;
                ClientService.getAll(this.clientGridsOptions.options, this.clientGridsOptions.filter).then(data => {
                    if (this.exclude) {
                        data.content = data.content.filter(el => this.exclude.indexOf(el.id) === -1);
                    }
                    this.clients = data.content;
                    this.totalElements = data.totalElements;
                    this.loading = false;
                });
            },
            fetchSubdivisions() {
                SubdivisionService.getAlls().then(data => {
                    this.subdivisions = data.content;
                });
            },
            fetchLawyers() {
                UserService.getAllLawyers().then(data => {
                    this.lawyers = data.content;
                });
            },
            fetchStates() {
                ClientService.getAllStates().then(data => {
                    this.states = data;
                });
            },
            deleteSelected() {
                ClientService.deleteBulk(this.selected).then(() => {
                    this.fetchGrid();
                    this.deleteBulkDialog = false;
                    this.selected = [];
                });
            },
            deleteItem(id) {
                ClientService.delete(id).then(() => {
                    this.fetchGrid();
                });
            },
            calcTableHeight() {
                return window.innerHeight - 200;
            },
            onResize() {
                this.tableHeight = this.calcTableHeight();
            },
            isVisibleColumn(column) {
                return this.headers.find(el => el.value === column) !== undefined;
            },
            editClient(id, prevItem, nextItem) {
                this.editedClient = id.toString();
                this.prevClient = !_.isUndefined(prevItem) ? prevItem.id : null;
                this.nextClient = !_.isUndefined(nextItem) ? nextItem.id : null;
                this.dialog.clientEdit.modal = true;
            },
            closeEdit() {
                this.dialog.clientEdit.modal = false;
                this.editedClient = null;
                this.editedClientKey++;
            },
            changeEdited(id) {
                this.editedClient = id;
                let clientIndex = this.clients.findIndex(el => {
                    return el.id == id;
                });

                let prevItem = this.clients[clientIndex - 1];
                let nextItem = this.clients[clientIndex + 1];

                this.prevClient = !_.isUndefined(prevItem) ? prevItem.id : null;
                this.nextClient = !_.isUndefined(nextItem) ? nextItem.id : null;

                this.editedClientKey++;
            },
            closeEditAndFetch() {
                this.closeEdit();
                this.fetchGrid()
            },
            isHighlighted(item) {
                return item.isNew || _.includes(this.selected, item.id)
            }
        },
        computed: {
            itemPerPage: function() {
                return [20, 30, 50, 100];
            },
            headers: function() {
                return  this.$helper.clientGridColumns.getForRole(this.user.role);
            },
            ...mapState(['user'])
        },
        created() {
            this.$store.dispatch('loading', false);
            this.fetch();

            this.$store.subscribeAction((action) => {
                if (action.type === 'newClientReceived' && !this.filter) {
                    let client = action.payload;
                    client.isNew = true;
                    this.clients.unshift(action.payload);
                }
            })
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            window.addEventListener('resize', this.onResize)
        }
    };
</script>
<style lang="scss">
    header {
        display: none !important;
    }
    .v-content {
        padding-top: 0 !important;
    }
</style>
