import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '../home/HomePage'
import LoginPage from '../login/LoginPage'
import Error from "../errors/Error";
import RequestCreatePage from "../requests/RequestCreatePage";
import RequestUpdatePage from "../requests/RequestUpdatePage";
import RequestOverviewPage from "../requests/RequestOverviewPage";
import UsersGrid from "../users/UsersGrid";
import UserUpdate from "../users/UserUpdate";
import UserCreatePage from "../users/UserCreatePage";
import SubdiviosionsGrid from "../subdivisions/SubdiviosionsGrid";
import SubdivisionUpdate from "../subdivisions/SubdivisionUpdate";
import SubdivisionCreatePage from "../subdivisions/SubdivisionCreatePage";
import AppointmentPage from "../appointments/AppointmentPage";
import ReminderPage from "../reminders/ReminderPage";
import ReportGrid from "../reports/ReportGrid";
import PaymentGrid from "../payments/PaymentGrid";
import ClientGrid from "../clients/ClientGrid";
import ClientCreatePage from "../clients/ClientCreatePage";
import ClientUpdatePage from "../clients/ClientUpdatePage";

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        {path: '/', component: HomePage, meta: {title: 'База заявок'}},
        {path: '/login', component: LoginPage, meta: {title: 'Вход', layout: 'auth-layout'}},
        {path: '/requests/create', component: RequestCreatePage, meta: {title: 'Добавить заявку'}},
        {path: '/requests/:id/edit', component: RequestUpdatePage, props: true, meta: {title: 'Редактирование заявки'}},
        {path: '/requests/:id', component: RequestOverviewPage, props: true, meta: {title: 'Обзор заявки'}},

        {path: '/clients', component: ClientGrid, meta: {title: 'База клиентов'}},
        {path: '/clients/create', component: ClientCreatePage, meta: {title: 'Добавить клиента'}},
        {path: '/clients/:id/edit', component: ClientUpdatePage, props: true, meta: {title: 'Редактирование клиента'}},

        {path: '/appointments', component: AppointmentPage, meta: {title: 'Список записей'}},

        {path: '/reminders', component: ReminderPage, meta: {title: 'Напоминания'}},

        {path: '/users', component: UsersGrid, meta: {title: 'Список сотрудников'}},
        {path: '/users/:id/edit', component: UserUpdate, props: true, meta: {title: 'Редактирование сотрудника'}},
        {path: '/users/create', component: UserCreatePage, meta: {title: 'Добавить сотрудника'}},

        {path: '/subdivisions', component: SubdiviosionsGrid, meta: {title: 'Список подразделений'}},
        {path: '/subdivisions/:id/edit', component: SubdivisionUpdate, props: true, meta: {title: 'Редактирование подразделения'}},
        {path: '/subdivisions/create', component: SubdivisionCreatePage, meta: {title: 'Добавить подразделение'}},

        {path: '/reports', component: ReportGrid, meta: {title: 'Отчеты'}},

        {path: '/payments', component: PaymentGrid, meta: {title: 'Оплаты'}},

        // otherwise 404 error
        {path: "*", component: Error}
    ]
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    document.title = to.meta.title || 'ЛенЮр | CRM';

    if (to.path === '/login' && loggedIn) {
        return next({
            path: '/'
        });
    }

    if (authRequired && !loggedIn) {
        return next({
            path: '/login',
            query: {returnUrl: to.path}
        });
    }

    next();
});
