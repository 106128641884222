<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="9">
                            <v-row v-if="client !== null">
                                <v-col>
                                    <v-text-field :value="form.id" disabled="disabled" label="ID"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select v-model="form.state" :items="states"
                                              :disabled="!isManualDrivenState(form.state) && !$helper.permission.canChangeNotManualState(user, form.state)"
                                              v-on:change="onChangeState"
                                              label="Статус"></v-select>
                                    <v-alert v-if="form.errors.has('state')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('state')}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="$helper.permission.canAssignClient(user)">
                                    <v-select v-model="form.lawyer" label="Юрист" :items="lawyers" item-value="id" item-text="fullName"></v-select>
                                    <v-alert v-if="form.errors.has('lawyer')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('lawyer') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canChangeSubdivision(user)">
                                    <v-select v-model="form.subdivision" label="Подразделение" :items="subdivisions" item-value="id" item-text="name"></v-select>
                                    <v-alert v-if="form.errors.has('subdivision')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('subdivision') }}
                                    </v-alert>
                                </v-col>
                                <v-col v-if="$helper.permission.canChangeSubdivision(user)">
                                    <v-select v-model="form.office" label="Офис" :items="offices" item-value="id" item-text="name"></v-select>
                                    <v-alert v-if="form.errors.has('office')" dense outlined type="error" :icon="false">
                                        {{form.errors.get('office') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row v-if="client !== null">
                                <v-col>
                                    <v-text-field :value="form.createdAt" disabled="disabled"
                                                  label="Дата создания"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field :value="form.assignedAt" disabled="disabled"
                                                  label="Дата привязки"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select v-model="form.area" :items="areas" item-value="id"
                                              item-text="name"
                                              label="Область права"></v-select>
                                    <v-alert v-if="form.errors.has('area')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('area')}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="4" xl="3">
                                    <v-text-field v-model="form.userName" label="Имя"></v-text-field>
                                    <v-alert v-if="form.errors.has('userName')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('userName') }}
                                    </v-alert>
                                </v-col>
                                <v-col lg="4"  xl="3">
                                    <v-text-field v-model="form.userPhone" label="Телефон"
                                                  v-mask="'+7 (###) ###-##-## #'"></v-text-field>
                                    <v-alert v-if="form.errors.has('userPhone')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('userPhone') }}
                                    </v-alert>
                                </v-col>
                                <v-col lg="4"  xl="3">
                                    <v-text-field v-model="form.userEmail" label="Email"></v-text-field>
                                    <v-alert v-if="form.errors.has('userEmail')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('userEmail') }}
                                    </v-alert>
                                </v-col>
                                <v-col lg="4"  xl="3">
                                    <v-text-field v-model="form.userBirthday" label="Год рождения"></v-text-field>
                                    <v-alert v-if="form.errors.has('userBirthday')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('userBirthday') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="form.problem" label="Проблема"></v-textarea>
                                    <v-alert v-if="form.errors.has('problem')" dense outlined type="error"
                                             :icon="false">
                                        {{form.errors.get('problem') }}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" xl="3">

                        </v-col>
                    </v-row>
                    <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                        Исправьте ошибки
                    </v-alert>
                    <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                        {{form.errors.get('error')}}
                    </v-alert>
                    <div v-show="!form.busy">
                        <v-btn large color="green" @click="client ? update(false) : save(false)">
                            Сохранить
                        </v-btn>
                    </div>
                    <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                </form>
            </v-card-text>
        </v-card>
        <div v-if="$helper.permission.canSeeRequest(user)" class="my-10">
            <RequestGrid v-if="client" :title="`Заявки клиента с номером: ${client.userPhone}`" :filter="{cleanPhone: client.cleanPhone, isDoubles: true}"></RequestGrid>
        </div>
        <CallsGrid v-if="client && $helper.permission.canSeeCalls(user)" :title="`Вызовы для номера: ${client.userPhone}`" :filter="{clientPhone: client.cleanPhone}"></CallsGrid>
        <RequestComments v-if="client.request" :id="client.request"></RequestComments>
    </div>
</template>

<script>
    import {Form} from '../_helpers/_form';
    import ClientService from "../_services/ClientService";
    import {mapState} from 'vuex';
    import RequestComments from "../requests/comments/RequestComments";
    import CallsGrid from "../calls/CallsGrid";
    import RequestGrid from "../requests/RequestGrid";

    export default {
        name: "ClientForm",
        components: {RequestGrid, CallsGrid, RequestComments},
        props: {
            page: {
                type: Object,
                default: null
            },
            inPopup: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                form: this.page.client
                    ? new Form(this.page.client)
                    : new Form({}),
                states: [], //only manual driven states are stored here
                allStates: [],
                lawyers: this.page.lawyers,
                subdivisions: this.page.subdivisions,
                offices: this.page.offices,
                client: this.page.client,
                areas: this.page.areas,
                loading: false
            }
        },
        methods: {
            fetch() {
                this.fetchStates();
            },
            fetchStates() {
                let data = this.page.states;
                this.allStates = data;
                data = data.filter(state => {
                    return !(state.manualDriven !== 'true' && this.form.state !== state.value);
                });
                data = data.filter(state => {
                    return !(!this.$helper.permission.canSetState(this.user, state) && this.form.state !== state.value);
                });
                this.states = data;
            },
            update() {
                ClientService.update(this.client.id, this.form).then(() => {
                    if (this.inPopup) {
                        this.$emit('closeAndUpdate');
                    } else {
                        this.$router.push('/clients');
                    }
                    this.$emit("closeAndUpdate");
                });
            },
            save() {
                ClientService.create(this.form).then(() => {
                    this.$router.push('/clients');
                });
            },
            isManualDrivenState(clientState) {
                let state = this.allStates.find(v => v.value === clientState);
                return state.manualDriven === 'true' || state.value === 'NEW';
            },
            onChangeState(state) {
                console.log(state);
            }
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    }
</script>

<style lang="scss">

</style>
