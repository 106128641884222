import {http} from "../_helpers";
import Service from "./Service";
import {axiosInstance} from "../_helpers/axiosInstance";

class ClientService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/clients`, options, filter));
    }

    getOne(id) {
        return http.get(`/clients/${id}`);
    }

    getForm(id) {
        return http.get(`/clients/${id}/update`);
    }

    create(form) {
        return http.post(`/clients`, form);
    }

    getCreatePage() {
        return http.get(`/clients/create`);
    }

    update(id, form) {
        return http.put(`/clients/${id}`, form);
    }

    delete(id) {
        return http.delete(`/clients/${id}`);
    }

    deleteBulk(ids) {
        return axiosInstance.post(`/clients/deleteBulk`, {'ids': ids});
    }

    getAllStates() {
        return http.get(`/clients/states`);
    }
}

export default new ClientService;
