<template>
    <div>
        <v-card-title>
            Список записей
            <v-btn icon @click="$emit('forceUpdate')">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table class="elevation-1"
                      :headers="headers"
                      :items="requests"
                      :options.sync="appointmentsGridsOptions.options"
                      :server-items-length="totalElements"
                      item-key="id"
                      :loading="loading"
                      loading-text="Загрузка ..."
                      :footer-props="{'items-per-page-options':[20, 30, 50]}"
        >
            <template v-slot:body="{ items }">
                <tbody>
                <tr>
                    <td width="240"></td>
                    <td width="120">
                        <v-text-field v-model="appointmentsGridsOptions.filter.id" type="text"></v-text-field>
                    </td>
                    <td width="180">
                        <v-select v-model="appointmentsGridsOptions.filter.state" :items="states" clearable></v-select>
                    </td>
                    <td width="200">
                        <v-dialog ref="dialog2"
                                  v-model="dialog.appointmentDate.modal"
                                  :return-value.sync="appointmentsGridsOptions.filter.appointmentDate"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="appointmentsGridsOptions.filter.appointmentDate"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        clearable
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.appointmentDate.model" type="date" scrollable
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.appointmentDate.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog2.save(dialog.appointmentDate.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td width="240" v-if="isVisibleColumn('userPhone')"><v-text-field v-model="appointmentsGridsOptions.filter.cleanPhone" type="text"></v-text-field></td>
                    <td width="300" v-if="isVisibleColumn('userName')"><v-text-field v-model="appointmentsGridsOptions.filter.userName" type="text"></v-text-field></td>
                    <td width="240" v-if="isVisibleColumn('operator')">
                        <v-select v-model="appointmentsGridsOptions.filter.operator" :items="operators" clearable
                                   item-value="id"
                                   item-text="fullName"></v-select>
                    </td>
                    <td width="200" v-if="isVisibleColumn('lawyer')">
                        <v-select v-model="appointmentsGridsOptions.filter.lawyers" :items="lawyers" clearable
                                  item-value="id"
                                  item-text="fullName"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')" width="160">
                        <v-select v-model="appointmentsGridsOptions.filter.subdivision" :items="subdivisions" clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('office')" width="160">
                        <v-select v-model="appointmentsGridsOptions.filter.office" :items="offices" clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                </tr>
                <tr v-for="item in items" :key="item.id" v-bind:class="{'grey darken-3': item.isNew}">
                    <td>
                        <v-row>
                            <v-col v-if="$helper.permission.canOverview(user)">
                                <v-icon small @click="$router.push(`/requests/${item.id}`)">mdi-eye-outline</v-icon>
                            </v-col>
                            <v-col v-if="$helper.permission.canSetComingState(user)" style="white-space: nowrap">
                                <v-btn v-if="item.state.value !== 'CAME'" @click="setRequestState(item.id, 'CAME')" color="green" class="mr-2" x-small
                                       outlined>
                                    Пришел
                                </v-btn>
                                <v-btn v-if="item.state.value !== 'NOT_CAME'" @click="setRequestState(item.id, 'NOT_CAME')" color="red" x-small outlined>
                                    Не пришел
                                </v-btn>
                            </v-col>
                            <v-col v-if="$helper.permission.canAssignLawyer(user) && !item.lawyer" style="white-space: nowrap">
                                <v-btn @click="openAssignLawyerDialog(item.id)" color="green" class="mr-2" x-small outlined>
                                    Отправить юристу
                                </v-btn>
                            </v-col>
                        </v-row>
                    </td>
                    <td>{{ item.id }}</td>
                    <td>
                        <v-chip :color="item.state.chipColor" outlined dark>
                            <v-icon v-if="item.appointmentApproved" color="green" small left>mdi-check</v-icon>
                            {{item.state.text}}
                        </v-chip>
                    </td>
                    <td>{{ $moment(item.appointmentDate).dateFormat() }} {{item.appointmentTime.text}}</td>
                    <td v-if="isVisibleColumn('userPhone')">{{item.userPhone}} <span v-if="item.userName">({{item.userName}})</span></td>
                    <td v-if="isVisibleColumn('userName')">
                        {{ item.userName }}
                    </td>
                    <td v-if="isVisibleColumn('operator')">
                        <div v-if="item.operator">{{ item.operator.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('lawyer')">
                        <div v-if="item.lawyer">{{ item.lawyer.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')">
                        <div v-if="item.subdivision">{{ item.subdivision.name }}</div>
                    </td>
                    <td v-if="isVisibleColumn('office')">
                        <div v-if="item.office">{{ item.office.name }}</div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-dialog
                v-model="dialog.assignLawyer.model"
                max-width="400"
        >
            <AssignLawyerForm :id="assignLawyerRequestId" @saved="fetchGrid" @close="dialog.assignLawyer.model=false"></AssignLawyerForm>
        </v-dialog>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import RequestService from "../_services/RequestService";
    import AssignLawyerForm from "./AssignLawyerForm";
    import UserService from "../_services/UserService";
    import SubdivisionService from "../_services/SubdivisionService";
    import OfficeService from "../_services/OfficeService";

    export default {
        name: "AppointmentsGrid",
        components: {AssignLawyerForm},
        data() {
            return {
                requests: [],
                states: [],
                operators: [],
                lawyers: [],
                subdivisions: [],
                offices: [],
                loading: true,
                totalElements: 0,
                dialog: {
                    appointmentDate: {
                        model: null,
                        modal: false
                    },
                    assignLawyer: {
                        model: null
                    },
                },
                assignLawyerRequestId: null
            }
        },
        watch: {
            appointmentsGridsOptions: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            }
        },
        methods: {
            fetch() {
                this.fetchStates();
                this.fetchLawyers();
                this.fetchOperators();
                if (this.isVisibleColumn('subdivision')) {
                    this.fetchSubdivisions();
                }
                if (this.isVisibleColumn('office')) {
                    this.fetchOffices();
                }
            },
            fetchSubdivisions() {
                SubdivisionService.getAlls().then(data => {
                    this.subdivisions = data.content;
                });
            },
            fetchOffices() {
                OfficeService.getAll().then(data => {
                    this.offices = data;
                });
            },
            fetchOperators() {
                UserService.getAllOperators().then(data => {
                    this.operators = data.content;
                });
            },
            fetchLawyers() {
                UserService.getAllLawyers().then(data => {
                    this.lawyers = data.content;
                });
            },
            fetchStates() {
                RequestService.getAllStates().then(data => {
                    this.states = data;
                });
            },
            fetchGrid() {
                this.loading = true;

                let gridOptions = JSON.parse(JSON.stringify(this.appointmentsGridsOptions.options));
                gridOptions.sortBy.push("appointmentTime");
                gridOptions.sortDesc.push(gridOptions.sortDesc[0]);

                RequestService.getAppointmentsList(gridOptions, this.appointmentsGridsOptions.filter).then(data => {
                    this.requests = data.content;
                    this.totalElements = data.totalElements;
                    this.loading = false;
                });
            },
            setRequestState(id, state) {
                this.loading = true;
                RequestService.setAppointmentState(id, state).then(response => {
                    if (state === 'CAME' && response.headers.location) {
                        this.$router.push(response.headers.location);
                    } else {
                        this.fetchGrid();
                    }
                });
            },
            openAssignLawyerDialog(id) {
                this.assignLawyerRequestId = id;
                this.dialog.assignLawyer.model = true;

            },
            isVisibleColumn(column) {
                return this.headers.find(el => el.value === column) !== undefined;
            }
        },
        computed: {
            headers: function() {
                return  this.$helper.appointmentGridColumns.getForRole(this.user.role);
            },
            ...mapState(['user', 'appointmentsGridsOptions'])
        },
        created() {
            this.fetch();
            this.$store.dispatch('loading', false);

            this.$store.subscribeAction((action) => {
                if (action.type === 'newRequestReceived') {
                    let request = action.payload;
                    request.isNew = true;
                    this.requests.unshift(action.payload);
                }
            });
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        }
    };
</script>
