import Vue from 'vue'
import {config} from "./constants";

const storage = window.localStorage;
export const helper = {};

/**
 * localStorage
 */
helper.ls = {
    set(key, value) {
        value = JSON.stringify(value);
        storage.setItem(key, value)
    },
    get(key, defaultValue) {
        let value = storage.getItem(key);
        if (value === null || value === 'undefined' || value === '') {
            value = defaultValue
        } else {
            value = JSON.parse(value)
        }
        return value
    },
    remove(key) {
        storage.removeItem(key)
    }
};

/**
 * a wrapper for helper.ls
 */
helper.store = (key, value) => {
    if (value === undefined) {
        return helper.ls.get(key)
    } else {
        return helper.ls.set(key, value)
    }
};

helper.url = {
    getRelative(url) {
        return url.replace(process.env.VUE_APP_API_URL, "");
    }
};

helper.removeOne = (array, predicate) => {
    for (var i = 0; i < array.length; i++) {
        if (predicate(array[i])) {
            return array.splice(i, 1);
        }
    }
};

helper.remove = (array, predicate) => {
    var removed = [];

    for (var i = 0; i < array.length;) {

        if (predicate(array[i])) {
            removed.push(array.splice(i, 1));
            continue;
        }
        i++;
    }
    return removed;
};

let defaultRequestColumns = [
    {sortable: false},
    {text: 'ID', value: 'id'},
    {text: 'Статус', value: 'state'},
    {text: 'Источник', value: 'source'},
    {text: 'Телефон', value: 'userPhone'},
    {text: 'Регистрация заявки', value: 'createdAt'},
    {text: 'Распределение заявки', value: 'assignedAt'},
    {text: 'Оператор', value: 'operator'},
    {text: 'Клиент', value: 'userName'},
];
helper.requestGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'OPERATOR':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Регистрация заявки', value: 'createdAt'},
                    {text: 'Распределение заявки', value: 'assignedAt'},
                    {text: 'Клиент', value: 'userName'}
                ];
                if (process.env.VUE_APP_HIDE_SOURCE_TO_OPERATORS !== 'true') {
                    columns = columns.insert(3, {text: 'Источник', value: 'source'});
                }
                break;
            case 'PROVIDER':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Телефон', value: 'userPhone'},
                    {text: 'Регистрация заявки', value: 'createdAt'},
                    {text: 'Распределение заявки', value: 'assignedAt'},
                    {text: 'Клиент', value: 'userName'}
                ];
                break;
            case 'ADMIN':
                columns = defaultRequestColumns;
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Подразделение', value: 'subdivision'}
                    ]);
                }
                break;
            default:
                columns = defaultRequestColumns;
                break;
        }
        return columns;
    }
};

let defaultClientColumns = [
    {sortable: false},
    {text: 'ID', value: 'id'},
    {text: 'Статус', value: 'state'},
    {text: 'Телефон', value: 'userPhone'},
    {text: 'Регистрация клиента', value: 'createdAt'},
    {text: 'Распределение клиента', value: 'assignedAt'},
    {text: 'Юрист', value: 'lawyer'},
    {text: 'Клиент', value: 'userName'},
];
helper.clientGridColumns = {
    getForRole(role) {
        let columns = [];
        switch (role) {
            case 'ADMIN':
                columns = defaultClientColumns;
                break;
            default:
                columns = defaultClientColumns;
                break;
        }
        return columns;
    }
};

let defaultAppointmentColumns = [
    {sortable: false},
    {text: 'ID', value: 'id'},
    {text: 'Статус', value: 'state'},
    {text: 'Время записи', value: 'appointmentDate'},
    {text: 'Телефон', value: 'userPhone'},
    {text: 'Оператор', value: 'operator'},
    //{text: 'Юрист', value: 'lawyer'}
];
helper.appointmentGridColumns = {
    getForRole(role) {
        let columns;
        switch (role) {
            case 'OPERATOR':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Время записи', value: 'appointmentDate'},
                    {text: 'Телефон', value: 'userPhone'}
                ];
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Офис', value: 'office'}
                    ]);
                }
                break;
            case 'RECEPTION':
                columns = [
                    {sortable: false},
                    {text: 'ID', value: 'id'},
                    {text: 'Статус', value: 'state'},
                    {text: 'Время записи', value: 'appointmentDate'},
                    {text: 'Клиент', value: 'userName'},
                    {text: 'Оператор', value: 'operator'}
                ];
                break;
            case 'ADMIN':
                columns = defaultAppointmentColumns;
                if (process.env.VUE_APP_USE_SUBDIVISIONS === 'true') {
                    columns = columns.concat([
                        {text: 'Подразделение', value: 'subdivision'},
                        {text: 'Офис', value: 'office'}
                    ]);
                }
                break;
            default:
                columns = defaultAppointmentColumns;
                break;
        }
        return columns;
    }
};


helper.callsGridColumns = {
    getForRole(role) {
        let columns;
        switch (role) {
            case 'ADMIN':
            case 'OPERATOR_MANAGER':
                columns = [
                    {text: 'Дата', value: 'createTime'},
                    {text: 'Направление', value: 'direction'},
                    {text: 'Сотрудник', value: 'user'},
                    {text: 'Длительность', value: 'time'},
                    {text: 'Записи', sortable: false}
                ];
                break;
            default:
                columns = [
                    {text: 'Дата', value: 'createTime'},
                    {text: 'Направление', value: 'direction'},
                    {text: 'Сотрудник', value: 'user'},
                    {text: 'Длительность', value: 'time'}
                ];
                break;
        }
        return columns;
    }
};

helper.isUserRole = (user, role) => {
    return user.role === role;
};

helper.env = {
    isTrue: (value) => {
        console.log(process.env);
        return process.env[value] === 'true';
    }
};

helper.showError = (error) => {
    error = error.response;
    console.log(error.status);
    const errorMsg = "Ошибка!";
    const statusMsg = config.errorStatusesMessages[error.status];
    const errorDetail = statusMsg ? statusMsg : "Произошла непредвиденная ошибка. Попробуйте обновить страницу.";
    return errorMsg + " " + error.status + ": " + errorDetail;
};

helper.permission = {
    // eslint-disable-next-line no-unused-vars
    canOverview(user) {
        return true;//user.role !== 'RECEPTION';
    },
    canSeeNewRequestNotification(user) {
        return user.role === 'OPERATOR_MANAGER' || user.role === 'OPERATOR';
    },
    canDeleteRequest(request, user) {
        return (user.role === 'ADMIN' && !request.hasProviders) || user.role === 'PROVIDER';
    },
    canDeleteClient(client, user) {
        return user.role === 'ADMIN';
    },
    canDeleteBulkRequest(user) {
        return user.role === 'ADMIN';
    },
    canAssignRequest(user) {
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER';
    },
    canAssignClient(user) {
        return user.role === 'ADMIN' || user.role === 'LAWYER_MANAGER' || user.role === 'RECEPTION';
    },
    canSeeRequest(user) {
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER' || user.role === 'OPERATOR' || user.role === 'PROVIDER';
    },
    canEditRequest(user) {
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER' || user.role === 'OPERATOR' || user.role === 'PROVIDER';
    },
    canSeeAppointments(user) {
        return user.role === 'RECEPTION' || user.role === 'LAWYER_MANAGER' || user.role === 'LAWYER';
    },
    canSetComingState(user) {
        return user.role === 'RECEPTION' || user.role === 'ADMIN';
    },
    canSeeCalls(user) {
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER';
    },
    canAssignLawyer(user) {
        return user.role === 'ADMIN' || user.role === 'LAWYER_MANAGER' || user.role === 'RECEPTION';
    },
    canSeeOverview(user) {
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER' || user.role === 'OPERATOR';
    },
    canChangeNotManualState(user, state) {
        if (state === 'ZAPIS') {
            return false;
        }
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER' || user.role === 'PROVIDER';
    },
    canSeeSourceOfRequest(user) {
        return user.role === 'ADMIN' || user.role === 'OPERATOR_MANAGER' || (user.role === 'OPERATOR' && process.env.VUE_APP_HIDE_SOURCE_TO_OPERATORS !== 'true');
    },
    canSetState(user, state) {
        if (state.roles.length > 0) {
            return state.roles.indexOf(user.role) >= 0;
        }
        return true;
    },
    canMakeCallback(user) {
        return user.innerPhoneNumbers.length > 0;
    },
    canSeeMoreRequestsOnGrid(user) {
        return user.role === 'ADMIN' || user.role === 'PROVIDER' || user.role === 'OPERATOR_MANAGER';
    },
    canChangeSubdivision(user) {
        return helper.env.isTrue('VUE_APP_USE_SUBDIVISIONS') && user.role === 'ADMIN'
    },
    canSeeTheBilling(user) {
        return helper.env.isTrue('VUE_APP_BILLING_ENABLED') && (user.role === 'ADMIN' || user.role === 'PROVIDER');
    }
};

Vue.directive('back', (el) => {
    el.onclick = () => window.history.go(-1)
});
