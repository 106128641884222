<template>
    <v-app>
        <component :is="layout">
            <transition mode="out-in" name="fade">
                <router-view></router-view>
            </transition>
        </component>
        <div class="flashpool">
            <flash-message></flash-message>
        </div>
    </v-app>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        data() {
            return {
                notificationAudio: new Audio(require('../_assets/sounds/notification.mp3'))
            }
        },
        computed: {
            ...mapState(['user', 'unMute']),
            layout() {
                return this.$route.meta.layout || 'default-layout'
            }
        },
        methods: {
            getReminder(reminder) {
                let result = `Напоминание: ${reminder.start} <br> ${reminder.name} <br>`;
                if (reminder.description) {
                    result += ` ${reminder.description} <br>`;
                }
                if (reminder.request) {
                    result += `Заявка <a href="/requests/${reminder.request.id}/edit" class="linkTo">#${reminder.request.id}</a><br>`;
                }
                return result;
            },
            playSound(audio) {
                if (this.unMute) {
                    audio.play();
                }
            }
        },
        created() {
            this.$store.subscribeAction((action) => {
                if (action.type === 'newRequestReceived' && this.$helper.permission.canSeeNewRequestNotification(this.user)) {
                    this.playSound(this.notificationAudio);
                    this.flashSuccess(`Новая заявка #${action.payload.id}`);
                }
                if (action.type === 'newClientReceived' && this.$helper.permission.canSeeNewClientNotification(this.user)) {
                    this.playSound(this.notificationAudio);
                    this.flashSuccess(`Новый клиент #${action.payload.id}`);
                }
                if (action.type === 'remindReceived') {
                    this.playSound(this.notificationAudio);
                    this.flashInfo(this.getReminder(action.payload));
                }
            });
        },
        mounted() {
            document.addEventListener("click", e => {
                if (e.target.classList.contains('linkTo')) {
                    e.preventDefault();
                    this.$router.push(e.target.getAttribute("href"));
                }
            })
        }
    }
</script>

<style lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .1s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }

    form {
        .v-alert.error--text {
            font-size: 12px;
            padding: 5px 0;
            line-height: 1;
            margin: -18px 0 18px;
            border: none !important;
        }
    }

    .flashpool {
        position: fixed;
        top: 20px;
        right: 20px;
        max-height: 400px;
        width: 320px;
        -webkit-perspective: 400px;
        perspective: 400px;
        z-index: 219;
    }

    .flash__message a {
        color: #fff !important;
        text-decoration: underline !important;
    }

    .flash__message.error {
        color: #fff !important;
    }

    .flash__message.success,
    .flash__message.info {
        color: #ffffff !important;
        font-size: 13px;
    }

    .v-application .flash__message.info {
        background-color: #6a1b9a !important;
        border-color: #6a1b9a !important;
    }

    .v-dialog--fullscreen {
        background: #121212;
    }

    .v-btn.active .v-icon {
        transform: rotate(360deg);
    }

    .v-data-table td, .v-data-table th {
        padding: 10px 16px !important;
    }

    .v-data-table tr.v-data-table__progress th {
        padding: 0 !important;
    }

    tr.row_sticky td {
        position: -webkit-sticky;
        position: sticky;
        top: 48px;
        z-index: 3;
        background: #1e1e1e;
    }

    .v-dialog--fullscreen {
        &.v-dialog--animated {
            animation: none;
        }
    }

    .table-footer-prepend {
        margin-top: -58px;
        height: 58px;
    }
</style>
