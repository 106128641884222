<template>
    <div>
        <v-card-title>
            Редактирование клиента
            <div class="text-right mx-3">
                <v-btn text color="error" @click="$emit('closeEdit')" icon>
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <ClientForm v-if="client !== null" :page="page" @closeAndUpdate="$emit('closeAndUpdate')"></ClientForm>
        </div>
    </div>
</template>

<script>
    import ClientForm from "./ClientForm";
    import {mapState} from 'vuex';
    import ClientService from "../_services/ClientService";

    export default {
        name: 'ClientUpdatePage',
        components: {ClientForm},
        props: {
            id: String,
            nextClient: Number,
            prevClient: Number,
            inPopup: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                client: null,
                page: null,
                loading: true
            }
        },
        methods: {
            fetch() {
                this.fetchClient();
            },
            fetchClient() {
                ClientService.getForm(this.id).then(data => {
                    this.page = data;
                    this.client = data.client;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            deleteItem(id) {
                ClientService.delete(id).then(() => {
                    this.$emit('closeAndUpdate')
                });
            }
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    };
</script>
